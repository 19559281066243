

let global_listener = false;

// Define the Component Class
class ColorSchemeSwitcher extends HTMLElement {

  constructor() {
    super();
    if (this.getAttribute("do-init") === "true") {
      ColorSchemeSwitcher.initDOM();
    }
  }

  connectedCallback() {
    // Cache prop values
    const btnClass = this.getAttribute("btn-class") || "nav-link";

    // Render the component
    this.innerHTML = `
      <div class="dropdown">
        <button type="button" class="d-flex ${btnClass}" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <figure class="d-inline-flex align-items-center mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-circle-half" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 0 8 1zm0 1A8 8 0 1 1 8 0a8 8 0 0 1 0 16"/>
            </svg>
          </figure>
        </button>
        <ul class="dropdown-menu dropdown-menu-end">
          <li><h6 class="dropdown-header">Select Theme</h6></li>
          <li><button type="button" data-bs-theme-value="dark" class="dropdown-item">Dark</button></li>
          <li><button type="button" data-bs-theme-value="light" class="dropdown-item">Light</button></li>
          <li><button type="button" data-bs-theme-value="auto" class="dropdown-item">System</button></li>
        </ul>
      </div>`;

    // Add click handlers for the buttons
    this.querySelectorAll(".dropdown-item").forEach(button => {
      button.addEventListener("click", () => {
        const themeValue = button.getAttribute("data-bs-theme-value") || "";
        ColorSchemeSwitcher.updateStoredScheme(themeValue);
        ColorSchemeSwitcher.updateDOMScheme(themeValue);
      });
    });
  }

  static getScheme() {
    const stored = ColorSchemeSwitcher.getStoredScheme();
    if (stored !== "auto") return stored;
    return ColorSchemeSwitcher.getOSSchemeSetting();
  }

  static getStoredScheme() {
    return localStorage.getItem("color-scheme") || "auto";
  }

  static getOSSchemeSetting() {
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  }

  static updateStoredScheme(theme = "auto") {
    localStorage.setItem("color-scheme", theme);
  }

  static updateDOMScheme(theme = "auto") {
    if (theme === 'auto') {
      document.documentElement.setAttribute('data-bs-theme', (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'))
    } else {
      document.documentElement.setAttribute('data-bs-theme', theme)
    }
  }

  static listenToOSThemeChange() {
    const h = () => {
      const storedScheme = ColorSchemeSwitcher.getStoredScheme();
      if (storedScheme === "auto") {
        const os_scheme = ColorSchemeSwitcher.getOSSchemeSetting();
        ColorSchemeSwitcher.updateDOMScheme(os_scheme);
      }
    };
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', h);
    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', h);
    }
  }

  static initDOM() {
    this.updateDOMScheme(this.getScheme());
    if (typeof global_listener === "function") global_listener();
    global_listener = this.listenToOSThemeChange();
    return global_listener;
  }
}

// Register the component globally
if (!customElements.get("color-scheme-switcher")) {
  customElements.define("color-scheme-switcher", ColorSchemeSwitcher);
}

// Return the class to allow importing and usage as a module
export default ColorSchemeSwitcher;